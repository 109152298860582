<template>
    <section class="view3d-page section p-0">
      <div class="overflow-auto">
        <section class="section content p-0">
          <div v-if="info.title" class="title is-5 m-5 mb-3 pb-3">{{ info.title }}</div>
            <div id="view3d-wrapper" ref="view3d" class="view3d-wrapper">
              <canvas class="view3d-canvas"></canvas>
            </div>
        </section>
      </div>
      
      <div class="link-buttons px-4 pb-2 columns is-mobile" v-if="info.buttons.length">
      <div v-for="(button,index) in info.buttons"
        class="column"
        :class="buttonClass(index)"
        :key="button.id">
        <a :href="button.link"
          class="button is-fullwidth is-primary is-outlined"
          target="_blank">
          <d-icon v-if="button.icon" :icon="button.icon" size="is-small" class="mr-1"/>
          {{ button.label }}
        </a>
      </div>
    </div>
  
    </section>
  </template>
  
  <script>
  // TODO: implementar version vue
  // "@egjs/vue-view3d"
  import View3D, { ARButton, LoadingBar } from "@egjs/view3d"
  import "@egjs/view3d/css/view3d-bundle.min.css"
  
  export default {
    components: {
    },
    props: {
      info: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        view3D: null
      };
    },
    computed: {
    },
    methods: {
      buttonClass(index) {
        if(this.info.buttons.length == 3 && index == 0) return 'is-full-mobile';
        if(this.info.buttons.length == 4) return 'is-half-mobile';
        return false
      }
    },
    mounted() {
      this.view3D = new View3D("#view3d-wrapper", {
        src: this.info.model.url,
        iosSrc: this.info.iosModel?.url,
        poster: this.info.poster?.url,
        skybox: this.info.skybox?.url,
        autoplay: this.info.autoplay,
        annotationURL: this.info.anotations?.url,
        arPriority: [
          "webAR",
          "sceneViewer",
          "quickLook"
        ],
        plugins: [
          new ARButton({
            availableText: this.$t('system.viewInAR'),
            buttonClass: 'view3d-ar-button'
          }),
          new LoadingBar({
            loadingLabel: this.$t('system.loading'),
            parsingLabel: this.$t('system.loading'),
            barForeground: 'var(--primary)'
          })],
        on: {
          ready: () => {

            if(!this.info.autoAr) return

            const arButton = this.$refs.view3d.querySelector('.view3d-ar-button')

            if (arButton && !arButton.disabled) arButton.click()

          }
        }
      })
    },
  };
  </script>
  <style lang="scss" scoped>
  @import '@/styles/variables.scss';
  
  .view3d-wrapper {
    width: 100%;
    height: 100%;
  }
  .view3d-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .section.content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  
    .overflow-auto {
      flex-grow: 1;
      height: 1px;
    }
    .title {
      border-bottom: solid 1px #E0E0E0;
    }
  
    img {
      object-fit: cover;
    }
  
    .column {
      padding: .5rem;
    }
    .columns:last-child {
      margin-bottom: 0;
    }
    .link-buttons {
      flex-wrap: wrap;
      background: $white-bis;
      z-index: 1;
    }
  
  }
  </style>

  <style lang="scss">
  .view3d-ar-button{
    top: 10px;
    bottom: auto;

    &:disabled {
    display: none
  }
  }


  </style>